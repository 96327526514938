var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"footer"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1"}),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"footer-baslik"},[_vm._v("Yardım")]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"footer-altbaslik"},[_c('router-link',{attrs:{"to":"/kaydol"}},[_c('span',[_vm._v("Yeni Üyelik")])])],1),_c('div',{staticClass:"footer-altbaslik"},[_c('router-link',{attrs:{"to":"/profil/geri-bildirim"}},[_c('span',[_vm._v("Geri Bildirim")])])],1)]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"footer-baslik"},[_vm._v("İletişim")]),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"footer-social"},[_c('SocialMediaIcons')],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"footer-bottom"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/img/logo-footer.png"),"alt":"logo"}})]),_c('span',[_vm._v("2023 ® Eren Sanal Market |")]),_vm._v(" Tüm hakları saklıdır. Kredi kartı bilgileriniz 256bit SSL sertifikası ile korunmaktadır. "),_vm._m(6)],1)])]),_vm._m(7)]),_c('div',{staticClass:"col-md-1"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"footer-baslik"},[_vm._v("Kurumsal")]),_c('div',{staticClass:"footer-altbaslik"},[_c('span',[_vm._v("Hakkimizda")])]),_c('div',{staticClass:"footer-altbaslik"},[_c('span',[_vm._v("Vizyon")])]),_c('div',{staticClass:"footer-altbaslik"},[_c('span',[_vm._v("Misyon")])]),_c('div',{staticClass:"footer-altbaslik"},[_c('span',[_vm._v("Iletisim")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-altbaslik"},[_c('span',[_c('a',{attrs:{"href":"aydinlatma.pdf"}},[_vm._v("Müşteri Aydınlatma Metni")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-altbaslik"},[_c('span',[_c('a',{attrs:{"href":"kvkk.pdf"}},[_vm._v("KVKK Politikası")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-altbaslik"},[_c('span',[_c('i',{staticClass:"fas fa-phone-alt"})]),_c('span',[_c('a',{attrs:{"href":"tel:0 (543) 211 03 09"}},[_vm._v("0 (543) 211 03 09")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-altbaslik"},[_c('span',[_c('i',{staticClass:"fas fa-envelope"})]),_c('span',[_c('a',{attrs:{"href":"mailto:siparis@erenmarket.com?subject=Bize Mail Bırakın"}},[_vm._v("siparis@erenmarket.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-ios-play"},[_c('span',[_c('a',{attrs:{"href":"https://apps.apple.com/tr/app/eren-market/id6449095380?l=tr","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/svg/icon-ios.svg"),"alt":"ios"}})])]),_c('span',[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.zdc.android.erenmarket&hl=TR"}},[_c('img',{attrs:{"src":require("@/assets/svg/icon-play.svg"),"alt":"play"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/visa-master.png"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"footer-son"},[_c('span',[_vm._v("Powered By "),_c('a',{attrs:{"href":"https://market.zdc.com.tr/","target":"_blank"}},[_vm._v("ZDC Bilişim")])])])])])
}]

export { render, staticRenderFns }